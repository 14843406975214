import { useOccupationsQuery, useOnboardingQuery, useProfileQuery } from '@api';
import { useUser } from '@modules/_app';

export enum MyAppsCallout {
  VERIFICATION_EMAIL = 'VERIFICATION_EMAIL',
  CHOOSE_OCCUPATION = 'CHOOSE_OCCUPATION',
  CHOOSE_PREFERRED_MONETIZATION = 'CHOOSE_PREFERRED_MONETIZATION'
}

export const useDashboardCallout = (): null | MyAppsCallout => {
  const { user } = useUser();
  const { data: onboardingData } = useOnboardingQuery();
  const { data: profileData } = useProfileQuery();
  const { data: dataOccupations } = useOccupationsQuery();

  if (!user || !onboardingData || !profileData || !dataOccupations) {
    return null;
  }

  const currentOccupation = dataOccupations.payload.find(
    ({ pid }) => pid === profileData.occupationPid
  );
  const isOccupationSuitable =
    currentOccupation &&
    ['freelancer', 'agency'].includes(currentOccupation.alias);

  if (!user?.verified) {
    return MyAppsCallout.VERIFICATION_EMAIL;
  }

  if (
    onboardingData.finish &&
    !onboardingData.occupationChosen &&
    !profileData.occupationPid
  ) {
    return MyAppsCallout.CHOOSE_OCCUPATION;
  }

  if (!profileData.preferredMonetizationPid && isOccupationSuitable) {
    return MyAppsCallout.CHOOSE_PREFERRED_MONETIZATION;
  }

  return null;
};
