import { NextPage } from 'next';
import { LayoutPage } from '@components';
import { Header } from '@modules/_header';
import { AppVirgin } from './app/app-virgin';

export const DashboardStartPage: NextPage = () => {
  return (
    <LayoutPage headerActivePage="/apps" header={<Header />}>
      <AppVirgin />
    </LayoutPage>
  );
};
